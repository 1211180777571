<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="branchform">
      <app-input v-model="data.name" name="Sicil Adı" label="Sicil Adı:" placeholder="Sicil Adı..." rules="required" />
      <app-input v-model="data.recordNo" name="İşyeri Sicil Numarası" label="İşyeri Sicil Numarası:" placeholder="İşyeri Sicil Numarası..." rules="required" />
      <riskstate-select v-model="data.riskState" required />
      <!-- Adres -->
      <provinces v-model="address.province" />
      <counties v-model="address.county" :province="address.province" />
      <districts v-model="address.district" :county="address.county" @selected="selectedDistrict" />
      <b-row>
        <b-col cols="7">
          <app-input v-model="address.street" name="Sokak / Cadde" label="Sokak / Cadde:" placeholder="Sokak / Cadde..." @input="handleInput" />
        </b-col>
        <b-col cols="5">
          <app-input v-model="address.buildingNo" name="Numara" label="Numara:" placeholder="Numara..." @input="handleInput" />
        </b-col>
      </b-row>
      <b-form-checkbox v-model="osbSelected" class="custom-control-danger mb-50" @change="getOsbOptions"> OSB - KSS </b-form-checkbox>
      <app-select-input
        v-if="osbSelected"
        v-model="address.osb"
        :reduce="(option) => option._id"
        name="OSB"
        placeholder="Seçim yapınız..."
        select_label="name"
        :options="osbOptions"
      />
      <hr />
      <b-row>
        <b-col cols="6">
          <app-button block text="Haritada Göster" icon="MapPinIcon" @click="setFullAddress" />
        </b-col>
        <b-col cols="6">
          <app-button block :text="updateItem ? 'Güncelle' : 'Kaydet'" icon="" @click="updateItem ? updateBranch() : newBranch()" />
        </b-col>
      </b-row>
      <hr />
      <!-- Harita -->
      <div v-show="address.district" ref="googleMap">
        <map-vue ref="mapApi" @location-selected="selectedLocation" />
        <hr />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
import mapVue from "@/views/Companies/companyForm/address/map.vue";
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver, mapVue },
  data() {
    return {
      typingTimeout: null,
      osbOptions: [],
      osbSelected: false,
      address: {},
      data: {},
      flag: false,
    };
  },
  computed: {
    companyId() {
      return this.$store.getters.selectedCompany?._id;
    },
    updateItem() {
      return this.$store.getters.appSidebarItem?.data;
    },
    callback() {
      return this.$store.getters.appSidebarItem?.callback;
    },
  },
  watch: {
    "address.province": function () {
      this.getOsbOptions();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.updateItem) {
        this.data = { ...this.updateItem };
        this.address = this.updateItem?.address ?? {};
        this.address.location = this?.data?.location;
        this?.address?.location?.lat &&
          this?.address?.location?.lng &&
          this.$nextTick(() => {
            this.$refs.mapApi.setLocation(this.address.location);
          });

        if (this?.data?.address?.osb && this?.data?.address?.province) {
          this.osbSelected = true;
        }
      }
    });
  },
  methods: {
    setData() {
      return { ...this.data, location: undefined, address: this.address, company: this.companyId };
    },
    newBranch() {
      this.$validate(this.$refs.branchform, () => {
        this.$store.dispatch("newBranch", { data: this.setData(), callback: this.callback });
      });
    },
    provinceSelected(item) {
      this.address.province = item;
    },
    updateBranch() {
      this.$validate(this.$refs.branchform, () => {
        this.$store.dispatch("updateBranch", {
          id: this.updateItem?._id,
          data: this.setData(),
          callback: this.callback,
        });
      });
    },
    setLocation(address) {
      this.$axios
        .post("/location/get-coordinates", {
          address,
        })
        .then((response) => {
          if (response.data) {
            this.$refs.mapApi.setLocation(response.data);
            this.address.location = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedDistrict(val) {
      if (val) {
        const { province, county, district } = this.address;
        this.setLocation(`${district.value} ${county.value} ${province.value}`);
      }
    },
    setFullAddress() {
      const { province, county, district, buildingNo, street } = this.address;
      if (province && county && district && buildingNo && street) {
        this.setLocation(`${street} ` + `No:${buildingNo} ${district.value} ${county.value} ${province.value}`);
      }
    },
    selectedLocation(location) {
      this.address.location = location;
    },
    getOsbOptions() {
      this.osbOptions = [];
      this.flag ? (this.address.osb = null) : (this.flag = true);
      if (this.address?.province && this.osbSelected) {
        this.$store
          .dispatch("getOsbList", this?.data?.address?.province._id)
          .then((data) => {
            this.osbOptions = data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleInput() {
      // Clear the previous timeout
      clearTimeout(this.typingTimeout);

      // Set a new timeout to trigger the event after 1 second of inactivity
      this.typingTimeout = setTimeout(() => {
        this.setFullAddress();
      }, 500);
    },
  },
};
</script>
